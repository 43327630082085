import type { UseFiltersModalParams } from './types';
import type { FiltersModalProps } from '@/components/filters-modal/types';
import type { LocationQueryRaw } from '#vue-router';
import type { AvailabilitiesCount, FloorPlans } from '~/shared/types';

export function useFiltersModal(options: UseFiltersModalParams) {
  const {
    type,
    countUrl,
    projectId,
    retainFiltersOnHide,
    onShowClick,
  } = options;

  const { $analytics, $api } = useNuxtApp();
  const configStore = useConfigStore();
  const { currency, square } = storeToRefs(configStore);
  const { resetFilters } = useFilterSort();
  const { showModal, hideModal } = useModal();

  const resultCount = ref(0);

  function showFiltersModal() {
    const component = defineAsyncComponent(() => import('@/components/filters-modal/FiltersModal.vue'));

    const data: FiltersModalProps = {
      type,
      resultCount,
    };
    showModal({
      component,
      data,
      emits: {
        fetchCount,
        onShowResultClick: _onShowResultClick,
      },
    });

    $analytics.trackEvent('open_filters', 'click');
  }

  async function fetchCount(params: LocationQueryRaw) {
    const result = await $api<AvailabilitiesCount | FloorPlans>(toValue(countUrl), {
      params: {
        ...params,
        projectId,
        smt: square.value,
        cur: currency.value,
        pageSize: 1,
      },
    });
    resultCount.value = result.total || result.count || 0;
  }

  function hideFiltersModal() {
    hideModal();
    if (!retainFiltersOnHide) {
      resetFilters();
    }
  }

  function _onShowResultClick() {
    if (onShowClick) {
      onShowClick();
    }
  }

  return {
    showFiltersModal,
    hideFiltersModal,
  };
}
